<template>
  <div class="row news-item">
    <div class="col-12 col-lg-7 text-col">
      <h4>{{ item.title }}</h4>
      <p class="text-small mb-4">{{ item.date }}</p>

      <p>{{ item.description }}</p>
      <div class="mt-2 mb-4">
        <a :href="item.link" target="_blank" rel="noopener noreferrer">
          <button class="btn-sm-accent">Read more</button>
        </a>
      </div>

      <div class="tags keywords-tags">
        <el-tag v-for="word in item.tags"> {{ word }}</el-tag>
      </div>
      <div class="tags location-tags">
        <el-tag>{{ item.location }}</el-tag>
      </div>
    </div>
    <div class="col-12 col-lg-5 img-col">
      <img class="img-fluid preview-img" :src="item.image" :alt="item.title" />
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'NewsItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>