<template>
  <div class="container forum-header mb-4">
    <app-header :title="texts.title" side="left">
      <template v-if="userLoggedIn">
        <button class="btn mb-4" @click="showModal = true">
          {{ texts.submit }}
        </button>
      </template>
      <app-no-auth :text="texts.noAuth" v-else />
    </app-header>
        <slot name="subheader" />
    <div class="row mb-4">
      <div class="col-12 col-lg-4 mb-2">
        <el-input
          v-model="filter"
          placeholder="Enter your search term(s)"
          size="large"
          @input="handleSearch"
          @change="handleSearch"
          clearable
        >
          <template #append>
            <div class="search-btn">
              <i class="bi bi-search"></i>
            </div>
          </template>
        </el-input>
      </div>
      <div class="col-12 col-lg-4"></div>
      <div class="col-12 col-lg-4">
        <el-select
          v-model="selectedKeyword"
          @change="handleKeywordChange"
          size="large"
          multiple
          clearable
          placeholder="Filter by keyword(s)"
        >
          <el-option
            v-for="item in selectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
  </div>
  <div
    class="container loader-container"
    v-loading="isLoading"
    v-if="isLoading"
  ></div>
  <div class="container forum-list mb-5" v-if="!isLoading">
    <div class="row pagination-row mt-4 mb-4">
      <app-pagination
        :total="total"
        :current-page="page"
        :page-size="limit"
        @page-change="handlePageChange"
      />
    </div>
    <template v-if="collection === 'news'">
      <news-item v-if="items?.length" v-for="item in items" :item="item" />
      <app-empty v-else>
        <img
          class="img-fluid"
          src="@/assets/img/no-result.png"
          alt="No result"
        />
        <p>No news matches your current search terms.</p>
      </app-empty>
    </template>
    <library-item :items="items" v-if="collection === 'library'" />
  </div>
  <scroll-top />
  <el-dialog v-model="showModal" destroy-on-close>
    <template #header>
      <h4>{{ texts.modalTitle }}</h4>
    </template>
    <app-form
      :form="form"
      :model="model"
      :rules="rules"
      button="Submit"
      button-side="right"
      @submitted="handleCreate"
    />
  </el-dialog>
</template>

<script>
import Database from '@/utils/services/Database';

import LibraryItem from '@/components/items/Library';
import NewsItem from '@/components/items/News';
import ScrollTop from '@/components/items/ScrollTop';

import keywords from '@/utils/options/keywords';
import data_categories from '@/utils/options/data_categories';

export default {
  name: 'PageItem',
  components: {
    LibraryItem,
    NewsItem,
    ScrollTop,
  },
  props: ['collection', 'limit', 'texts'],
  data() {
    return {
      items: [],
      showModal: false,
      form: [],
      model: {},
      rules: {},
      page: 1,
      isLoading: false,
      filter: null,
      selectedKeyword: [],
      total: 0,
      keywords: keywords,
      data_categories: data_categories,
      selectOptions: [],
    };
  },
  computed: {
    userLoggedIn() {
      return this.$store.state.auth.userLoggedIn;
    },
  },
  async created() {
    await this.getConfig();
    await this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const res = await Database.getAll(this.collection, {
        limit: this.limit,
        offset: this.page * this.limit - this.limit,
        filter: this.filter,
        keyword: this.selectedKeyword,
      });

      this.items = res.items;
      this.total = res.total;

      this.isLoading = false;
    },
    async getConfig() {
      this.selectOptions =
        this.collection === 'news' ? this.keywords : this.data_categories;
      const { form, model, rules } = await import(
        `@/utils/config/${this.collection}.js`
      );
      this.form = JSON.parse(JSON.stringify(form));
      this.model = JSON.parse(JSON.stringify(model));
      this.rules = JSON.parse(JSON.stringify(rules));
    },
    async handleSearch(query) {
      this.filter = query;
      this.selectedKeyword = [];
      await this.getData();
    },
    async handlePageChange(val) {
      this.page = val;
      await this.getData();
    },
    async handleKeywordChange(val) {
      this.filter = null;
      await this.getData();
    },
    async handleCreate(data) {
      const status = await Database.create(this.collection, data);

      if (status !== 201)
        return this.$message({
          type: 'error',
          message: 'Something went wrong. Please try again later.',
          offset: 200,
        });

      this.$message({
        type: 'success',
        message: this.texts.success,
        offset: 200,
      });

      this.showModal = false;
      await this.getData();
    },
  },
};
</script>
